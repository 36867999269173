export const formatConfig = {
  // hls: '',
  mp4: ['720p', '1080p', '2160p'],
  webm: ['720p', '1080p', '2160p'],
} as const

export const dimensions = {
  '1080p': { height: 1080, width: 1920 },
  '1440p': { height: 1440, width: 2560 },
  '2160p': { height: 2160, width: 3840 },
  '480p': { height: 480, width: 640 },
  '720p': { height: 720, width: 1280 },
} as const

export const bitrates = {
  h264: {
    '1080p': 8000,
    '1440p': 16000,
    '2160p': 40000,
    '480p': 2500,
    '720p': 5000,
  },
  vp8: {
    '1080p': 7000,
    '1440p': 14000,
    '2160p': 35000,
    '480p': 2000,
    '720p': 4500,
  },
  vp9: {
    '1080p': 5000,
    '1440p': 12000,
    '2160p': 25000,
    '480p': 1500,
    '720p': 3500,
  },
}

export const formats = Object.keys(formatConfig) as Format[]

export type Config = typeof formatConfig

export type Format = keyof typeof formatConfig

export type Resolution = keyof typeof dimensions
