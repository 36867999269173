import type { Accessor, JSX } from 'solid-js'
import { createContext, untrack, useContext as useSolidContext } from 'solid-js'
import type { SetStoreFunction } from 'solid-js/store'
import { createStore } from 'solid-js/store'

import { createModuleContextValue } from './createModuleContextValue'

const createModule = <
  S extends object,
  M extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends Record<string, (...args: any[]) => unknown>,
>({
  createActions,
  initialState,
  mapState,
  name,
  log,
}: {
  initialState: S
  mapState: (state: S) => Accessor<M>
  createActions: (mappedState: M, setState: SetStoreFunction<S>) => A
  name: string
  log?: typeof console.log
}) => {
  const createContextValue = (
    store: [state: S, setState: SetStoreFunction<S>],
    getInitialState: () => S,
  ) =>
    createModuleContextValue({
      store,
      createActions,
      getInitialState,
      mapState,
      name,
      log,
    })

  const Context = createContext<ReturnType<typeof createContextValue>>()

  const ContextProvider = (props: {
    children: JSX.Element
    state?: Partial<S>
  }) => {
    const initialStateWithPropState = () => ({
      ...initialState,
      ...props.state,
    })

    const [getState, setState] = createStore<S>(
      untrack(() => initialStateWithPropState()),
    )

    return (
      <Context.Provider
        value={createContextValue(
          [getState, setState],
          initialStateWithPropState,
        )}
      >
        {props.children}
      </Context.Provider>
    )
  }

  const useContext = () => {
    const context = useSolidContext(Context)

    if (typeof context === 'undefined') {
      throw new Error(`Context "${name}" needs to be wrapped in a provider`)
    }
    return context
  }
  const useState = () => useContext()[0]
  const useActions = () => useContext()[1]
  const useEvent = <E extends keyof A>(eventName: E, listener: A[E]) =>
    useContext()[2].useEvent(eventName, listener)
  const useEventOnce = <E extends keyof A>(eventName: E, listener: A[E]) =>
    useContext()[2].useEventOnce(eventName, listener)

  return {
    ContextProvider,
    useContext,
    useState,
    useActions,
    useEvent,
    useEventOnce,
  }
}

export { createModule }
