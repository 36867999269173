import { authClient } from '../../authClient'
import { authLogger } from '../../authLogger'
import type { createHandleToken } from './createHandleToken'
import type { createResetState } from './createResetState'

export function createRegisterWithEmailAndPassword({
  setLoading,
  handleToken,
  resetState,
}: {
  handleToken: ReturnType<typeof createHandleToken>
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  return async ({ email, password }: { email: string; password: string }) => {
    try {
      setLoading()
      const { accessToken } =
        await authClient.registerWithEmailAndPassword.mutate({
          email,
          password,
        })

      authLogger.info('Successfully registered in with email and password')

      handleToken({ accessToken })
    } catch (error) {
      authLogger.error(
        'Error while registering in with username and password',
        error,
      )
      resetState()
    }
  }
}
