import { Subscriptions } from 'capacitor-subscriptions'
import { createEffect, createSignal, onMount } from 'solid-js'

import { useAuth } from '#/auth/ui/AuthProvider'
import { getPlatform } from '#/utils/getPlatform'

import type { HandleCheckoutSucceeded } from './createHandleCheckoutSucceeded'
import type { SubscriptionState } from './mapSubscriptionState'

export const createRecoverGooglePurchases = (
  state: SubscriptionState,
  handleCheckoutSucceeded: HandleCheckoutSucceeded,
) => {
  const auth = useAuth()
  const [hasRecoveredPurchases, setHasRecoveredPurchases] = createSignal(false)

  const recoverPurchases = async () => {
    await auth.waitForAuthentication()
    const { entitlements } = await Subscriptions.getCurrentEntitlements()

    await Promise.all(
      entitlements.map(async (transaction) => {
        if (
          'isAcknowledged' in transaction &&
          !transaction.isAcknowledged &&
          transaction.purchaseToken
        ) {
          await handleCheckoutSucceeded({
            provider: 'google',
            purchaseToken: transaction.purchaseToken,
            subscriptionId: transaction.productIdentifier,
          })
        }
      }) ?? [],
    )
  }

  onMount(() => {
    if (getPlatform() !== 'android') return

    Subscriptions.addListener(
      'ANDROID-PURCHASE-SUCCESS',
      async (transaction) => {
        console.log('ANDROID-PURCHASE-SUCCESS', transaction)

        const { productIdentifier, purchaseToken } = transaction

        await handleCheckoutSucceeded({
          provider: 'google',
          purchaseToken,
          subscriptionId: productIdentifier,
        })
      },
    )
  })

  createEffect(() => {
    if (getPlatform() !== 'android') return

    if (state.isLoading) return
    if (state.hasActiveSubscription) return
    if (hasRecoveredPurchases()) return

    setHasRecoveredPurchases(true)
    recoverPurchases().catch(console.error)
  })
}
