import { z } from 'zod'

import { countInModes } from '#/components/Player/state/initialPlayerState'
import type { Resolution } from '#/lambdaFunctions/utils/formatConfig'
import { dimensions } from '#/lambdaFunctions/utils/formatConfig'
import { zodLiteralUnion } from '#/utils/zodLiteralUnion'

const resolutions = Object.keys(dimensions) as Resolution[]

export const UserSettingsSchema = z.object({
  player: z.object({
    isMirrored: z.boolean().optional(),
    metronome: z
      .object({
        isEnabled: z.boolean().optional(),
        volume: z.number().optional(),
        countIn: zodLiteralUnion(countInModes).optional(),
      })
      .optional(),
    preferredResolution: zodLiteralUnion(resolutions).optional(),
  }),
})

export type UserSettings = z.infer<typeof UserSettingsSchema>
