import type { MUUID } from 'uuid-mongodb'
import { z } from 'zod'

const StripeSubscriptionSchema = z.object({
  provider: z.literal('stripe'),
  customerId: z.string(),
  details: z
    .object({
      createdAt: z.date(),
      status: z.enum([
        'incomplete',
        'incomplete_expired',
        'trialing',
        'active',
        'past_due',
        'canceled',
        'unpaid',
        'paused',
      ]),
      currentPeriodStartsAt: z.date(),
      currentPeriodEndsAt: z.date(),
      currency: z.string(),
      subscriptionId: z.string(),
    })
    .optional(),
})

const AppleSubscriptionSchema = z.object({
  provider: z.literal('apple'),
  appAccountToken: z.custom<MUUID>(),
  details: z
    .object({
      transactionId: z.string(),
      createdAt: z.date(),
      currentPeriodStartsAt: z.date(),
      currentPeriodEndsAt: z.date(),
    })
    .optional(),
})

const GoogleSubscriptionSchema = z.object({
  provider: z.literal('google'),
  obfuscatedAccountId: z.custom<MUUID>(),
  details: z
    .object({
      createdAt: z.date(),
      currentPeriodStartsAt: z.date(),
      currentPeriodEndsAt: z.date(),
      orderId: z.string(),
      purchaseToken: z.string(),
    })
    .optional(),
})
export const UserSubscriptionSchema = z.union([
  StripeSubscriptionSchema,
  AppleSubscriptionSchema,
  GoogleSubscriptionSchema,
])

export type UserSubscription = z.infer<typeof UserSubscriptionSchema>
