import type { SetStoreFunction } from 'solid-js/store'

import type { HandleCheckoutSucceeded } from './createHandleCheckoutSucceeded'
import { getProvider } from './getProvider'
import type { InitialSubscriptionState } from './initialState'
import type { SubscriptionState } from './mapSubscriptionState'

export const createStartCheckout = (
  state: SubscriptionState,
  setState: SetStoreFunction<InitialSubscriptionState>,
  handleCheckoutSucceeded: HandleCheckoutSucceeded,
) => {
  const createStartCheckoutPromise = async () => {
    const provider = getProvider()

    switch (provider) {
      case 'apple': {
        const { createStartAppleCheckout } = await import(
          './createStartAppleCheckout'
        )
        return createStartAppleCheckout(
          state,
          setState,
          handleCheckoutSucceeded,
        )
      }
      case 'google': {
        const { createStartGoogleCheckout } = await import(
          './createStartGoogleCheckout'
        )
        return createStartGoogleCheckout(
          state,
          setState,
          handleCheckoutSucceeded,
        )
      }
      case 'stripe': {
        const { createStartStripeCheckout } = await import(
          './createStartStripeCheckout'
        )
        return createStartStripeCheckout(state, setState)
      }
    }
  }

  const startCheckoutPromise = createStartCheckoutPromise()

  return async () => {
    const startCheckout = await startCheckoutPromise
    return startCheckout()
  }
}
