import type { User } from '#/db/types/User'

const hasActiveSubscriptionUntil = (subscription: User['subscription']) => {
  return subscription?.details &&
    subscription.details.currentPeriodStartsAt < new Date() &&
    subscription.details.currentPeriodEndsAt > new Date()
    ? subscription.details.currentPeriodEndsAt
    : undefined
}

export { hasActiveSubscriptionUntil }
