import { ServerEnvSchema } from './server/serverEnv'
import { envStore } from './utils/envStore'

// NOTE: Be careful to not expose secret values from the server to the client here
const ClientEnvSchema = ServerEnvSchema.pick({
  SITE_URL: true,
  STACK_NAME: true,
})

const clientEnv = ClientEnvSchema.parse(envStore)

export { clientEnv, ClientEnvSchema }
