import type { ObjectId } from 'mongodb'
import { z } from 'zod'

import { UserSettingsSchema } from './UserSettings'
import { UserSubscriptionSchema } from './UserSubscription'

export const UserRoleSchema = z.union([z.literal('ADMIN'), z.literal('USER')])

export type UserRole = z.infer<typeof UserRoleSchema>

export const UserSchema = z.object({
  _id: z.custom<ObjectId>(),
  createdAt: z.date(),
  updatedAt: z.date(),
  email: z.string().email().optional(),
  givenName: z.string().optional(),
  familyName: z.string().optional(),
  appleId: z.string().optional(),
  googleId: z.string().optional(),
  password: z.string().optional(),
  roles: z.set(UserRoleSchema),
  subscription: UserSubscriptionSchema.optional(),
  settings: UserSettingsSchema.optional(),
})

type User = z.infer<typeof UserSchema>

export type { User }
