import { authClient } from '../../authClient'
import { authLogger } from '../../authLogger'
import type { createHandleToken } from './createHandleToken'
import type { createResetState } from './createResetState'

export function createRefreshAccessToken({
  setLoading,
  handleToken,
  resetState,
}: {
  handleToken: ReturnType<typeof createHandleToken>
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  return async () => {
    try {
      authLogger.info('Refreshing access token...')
      setLoading()
      const { accessToken } = await authClient.getAccessToken.mutate()

      handleToken({ accessToken })
    } catch (error) {
      authLogger.error('Error while refreshing access token...', error)
      resetState()
    }
  }
}
