import { authClient } from '../../authClient'
import { authLogger } from '../../authLogger'
import type { createHandleToken } from './createHandleToken'
import type { createResetState } from './createResetState'

export function createLoginWithEmailAndPassword({
  setLoading,
  handleToken,
  resetState,
}: {
  handleToken: ReturnType<typeof createHandleToken>
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  return async ({ email, password }: { email: string; password: string }) => {
    try {
      setLoading()
      const { accessToken } = await authClient.loginWithEmailAndPassword.mutate(
        { email, password },
      )

      authLogger.info('Successfully logged in with email and password')

      handleToken({ accessToken })
    } catch (error) {
      authLogger.error(
        'Error while logging in with username and password',
        error,
      )
      resetState()
    }
  }
}
