import { z } from 'zod'

import { UserRoleSchema } from '#/db/types/User'

export const TokenDataSchema = z.object({
  sessionId: z.string(),
  expiresAt: z.number().transform((timestamp) => new Date(timestamp * 1000)),
  subscriptionExpiresAt: z
    .number()
    .transform((timestamp) => new Date(timestamp * 1000))
    .optional(),
  issuedAt: z.number().transform((timestamp) => new Date(timestamp * 1000)),
  issuer: z.string(),
  audience: z.string(),
  roles: z.set(UserRoleSchema),
})
