import type { Resolution } from '#/lambdaFunctions/utils/formatConfig'

import type { Song } from './transformSong'

export type Step = Song['steps'][number]

export enum PlaybackSpeed {
  Half = 0.5,
  Slower = 0.75,
  Normal = 1,
  Double = 2,
}

export type CountInMode = (typeof countInModes)[number]
export type InitialPlayerState = typeof initialPlayerState

export const countInModes = ['start', 'always', 'never'] as const

export const initialPlayerState = {
  canPlay: false,

  currentPosition: {
    easeTime: 0,
    stepIndex: 0,
    time: 0,
    timeAtStart: 0,
  },

  metronome: {
    countIn: 'start' as CountInMode,
    isEnabled: true,
    note: '4n' as '4n' | '8n' | '16n',
  },

  isBuffering: false,
  isDraggingLoop: false,
  isDraggingSheet: false,
  isEasing: false,
  isMirrored: false,
  isVideoPlaying: false,

  isPlaying: false,

  loadingProgress: 0,

  lensPosition: {
    x: 0.12,
    y: 0.52,
  },

  loop: {
    isLooping: false,
    loopEndIndex: 0,
    loopStartIndex: 0,
  },

  playbackSpeed: PlaybackSpeed.Normal,

  sheetWidth: 0,
  showLens: false,
  songId: '',

  preferredResolution: undefined as Resolution | undefined,
  videoElement: undefined as HTMLVideoElement | undefined,

  textOverlay: '',
}
