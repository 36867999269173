import { decodeJwt } from 'jose/jwt/decode'

import { TokenDataSchema } from './TokenDataSchema'

export const decodeAccessToken = (accessToken: string) => {
  const {
    roles,
    exp: expiresAt,
    iat: issuedAt,
    sub: sessionId,
    subExp: subscriptionExpiresAt,
    aud: audience,
    iss: issuer,
  } = decodeJwt<{
    subExp?: number
    roles?: string[]
  }>(accessToken)

  console.log({
    sessionId,
    expiresAt,
    subscriptionExpiresAt,
    issuedAt,
    issuer,
    audience,
    roles,
  })

  return TokenDataSchema.parse({
    sessionId,
    expiresAt,
    subscriptionExpiresAt,
    issuedAt,
    issuer,
    audience,
    roles: new Set(roles),
  })
}
