import type { SetStoreFunction } from 'solid-js/store'

import type { HandleCheckoutSucceeded } from './createHandleCheckoutSucceeded'
import { getProvider } from './getProvider'
import type { InitialSubscriptionState } from './initialState'
import type { SubscriptionState } from './mapSubscriptionState'

export function createConfirmPayment(
  state: SubscriptionState,
  setState: SetStoreFunction<InitialSubscriptionState>,
  handleCheckoutSucceeded: HandleCheckoutSucceeded,
) {
  const createStartCheckoutPromise = async () => {
    const provider = getProvider()

    switch (provider) {
      case 'apple': {
        return () => {}
      }
      case 'google': {
        return () => {}
      }
      case 'stripe': {
        const { createConfirmStripePayment } = await import(
          './createConfirmStripePayment'
        )
        return createConfirmStripePayment(
          state,
          setState,
          handleCheckoutSucceeded,
        )
      }
    }
  }

  const startCheckoutPromise = createStartCheckoutPromise()

  return async () => {
    const startCheckout = await startCheckoutPromise
    return startCheckout()
  }
}
