import type { JSX } from 'solid-js'
import { createContext, useContext } from 'solid-js'

import { createAuthContext } from './state/createAuthContext'

export type AuthState = ReturnType<typeof createAuthContext>

const initialAuthState: AuthState = {
  accessToken: undefined,
  expiresAt: undefined,
  issuedAt: undefined,
  roles: undefined,
  isLoggedIn: false,
  isLoggedOut: false,
  isLoading: true,
  signInWithApple: () => Promise.resolve(),
  refreshAccessToken: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  registerWithEmailAndPassword: () => Promise.resolve(),
  loginWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  waitForAuthentication: () => Promise.resolve(),
}

const AuthContext = createContext(initialAuthState)

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = (props: { children: JSX.Element }) => {
  const contextValue = createAuthContext()

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}
