import type { inferRouterInputs } from '@trpc/server'
import type { SetStoreFunction } from 'solid-js/store'

import { useAuth } from '#/auth/ui/AuthProvider'
import { trpc } from '#/trpc/client'
import type { AppRouter } from '#/trpc/server'

import type { InitialSubscriptionState } from './initialState'

export type HandleCheckoutSucceeded = ReturnType<
  typeof createHandleCheckoutSucceeded
>
export function createHandleCheckoutSucceeded(
  setState: SetStoreFunction<InitialSubscriptionState>,
) {
  const auth = useAuth()

  return async (args: inferRouterInputs<AppRouter>['handleSuccess']) => {
    setState({ isMutationLoading: true })

    const subscription = await trpc.handleSuccess.mutate(args)

    await auth.refreshAccessToken()

    setState({ subscription })
    setState({ isMutationLoading: false, isDialogOpen: false })
  }
}
